<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex=1 />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">企业资格认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <template v-if="companycertlistdata.length>=0">
                    <div @click="toaddcompanycert(item.id)" v-for="item in companycertlistdata" v-bind:key="item.id"
                      class="property-box mb-20">
                      <div class="assets-box">
                        <div class="assets-box-l">
                          <img src="../../assets/img/money.png">
                        </div>
                        <div class="assets-box-t js-show-asset-vip-lelvel-bg">
                          <p class="title">{{item.data_title}}</p>
                          <p class="creditcode">
                            <span>{{item.p_data_title}}</span>
                          </p>
                        </div>
                        <div class="assets-box-i">
                          <i class="el-icon-arrow-right"></i>
                        </div>
                      </div>
                      <div class="assets-sort">
                        <div class="lt">
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="companycertlistdata.length==0">
                    <div class="empty">
                      <i class="icon el-icon-sunrise"></i>
                      <span>暂无认证信息</span>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <userfooter />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import { companylist, companycertlist } from '@/api/user';
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      activeName: 'first',
      companycertlistdata: [],
      companyid: 0,
    };
  },
  methods: {
    toaddcompanycert(certtypeid) {
      this.$router.push(
        '/user/companycert?companyid=' +
          this.companyid +
          '&certtypeid=' +
          certtypeid
      );
    },
    oncompanycertlistlist() {
      var that = this;
      var data = { companyId: that.companyid };
      companycertlist(data).then((res) => {
        if (res.data.code === 1) {
          that.companycertlistdata = res.data.result;
        }
      });
    },
  },
  created() {
    this.companyid = this.$route.query.companyid;
    this.oncompanycertlistlist();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb .property-box {
  margin-bottom: 20px;
  border: 1px solid #3a8ee6;
}
.accountset .content-rb .assets-box-t {
  color: #3a8ee6;
  padding: 4px 0 7px 20px;
  border-radius: 4px;
  width: 500px;
  float: left;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .content-rb .assets-box-i {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  margin-top: 26px;
  font-size: 20px;
  color: #3a8ee6;
}
.accountset .content-rb .assets-box {
  position: relative;
}
.accountset .content-rb .property-box {
  transition: all 0.2s linear;
}
.accountset .content-rb .property-box:hover {
  transform: translate3d(0px, -1px, 0);
  border: 1px solid #ddd;
}
.accountset .content-rb .assets-box .assets-box-img {
  position: absolute;
  right: -2px;
  top: -18px;
}
.accountset .content-rb .assets-box-t .title {
  font-size: 22px;
  color: #3a8ee6;
  border: none;
}

.accountset .content-rb .assets-box-t p span {
  line-height: 25px;
  font-size: 15px;
  font-weight: 100;
}

.accountset .assets-box .assets-box-l {
  width: 40px;
  height: 40px;
  float: left;
  margin-top: 18px;
  margin-left: 10px;
  background: linear-gradient(196deg, #72b2ff, #0074ff);
  border-radius: 50%;
}
.accountset .assets-box .assets-box-l img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 24%;
}
.assets-sort {
  background: #fff;
  clear: both;
}
.assets-sort .lt {
  padding: 0 20px;
  border: 1px solid #ebeef5;
  border-top: none;
}
.assets-sort .lt .el-collapse {
  border: none;
}
</style>
